














































import { Vue, Component, Prop } from 'vue-property-decorator';
import { DataTableHeader } from "vuetify";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { VesselEnquiryModel } from "@/api/generated";
import nameof from "@/utility/nameof";
import UserModule from "@/store/userModule";
import { ContractType } from '../../../api/generated/api';

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselEnquiryDataTable extends Vue {
  @Prop({default: ContractType.NUMBER_0}) discriminator!: ContractType;
  @Prop({default: "Closed Vessels Enquiries"}) title?: string;

  private loading = false;
  private vessels: Array<VesselEnquiryModel> = [];
  private headers: Array<DataTableHeader<VesselEnquiryModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: this.title !== "Closed Vessels Enquiries" ? "Company" : "Vessel", value: nameof<VesselEnquiryModel>("vesselName") },
    { text: "Contract Start", value: nameof<VesselEnquiryModel>("startDate") },
    { text: "Contract End", value: nameof<VesselEnquiryModel>("endDate") },
    { text: "Monthly Fee", value: nameof<VesselEnquiryModel>("monthlyFeeGbp") },
    {
      text: this.title !== "Closed Vessels Enquiries" ? "Company Contact" : "Vessel Contact",
      value: nameof<VesselEnquiryModel>("vesselContactName")
    }
  ];

  private async created() {
    await this.getVesselEnquires();
  }

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async getVesselEnquires() {
    try {
      this.loading = true;
      const response = await Api.VesselEnquiryService.apiVesselenquiryCrewmemberpastcontractsCrewMemberIdDiscriminatorGet(
        this.$route.params.crewMemberId,
        this.title !== "Closed Vessels Enquiries" ? ContractType.NUMBER_1 : ContractType.NUMBER_0
      );
      this.vessels = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch vessel enquires");
    } finally {
      this.loading = false;
    }
  }

  private onEditVesselEnquiry(model: VesselEnquiryModel) {
    if (!model.vesselEnquiryId) {
      return;
    }

    this.$router.push({
      name: "Vessel Enquiry Edit",
      params: { vesselEnquiryId: model.vesselEnquiryId }
    });
  }

  private validityStyle = (item: VesselEnquiryModel) => {
    return item.valid ? "" : "error--text";
  };
}
