var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vessel-enquiry-closed-data-table"},[_c('base-data-table',{attrs:{"title":_vm.title,"headers":_vm.headers,"items":_vm.vessels,"loading":_vm.loading,"item-class":_vm.validityStyle,"no-create":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.agencyAdminRole)?_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onEditVesselEnquiry(item)}}},[_c('v-list-item-title',[_vm._v("View")])],1)],1)],1):_vm._e()]}},{key:"item.vesselName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vesselName)+" "+_vm._s(item.vesselRegistrationNumber)+" ")]}},{key:"item.monthlyFeeGbp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyGbp")(item.monthlyFeeGbp))+" ")]}},{key:"item.lastInvoicedUtc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.lastInvoicedUtc))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.endDate))+" ")]}},{key:"item.statusDescription",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("closedStatus")(item.statusDescription))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }