











import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import VesselEnquiryCrewMemberPastContractsTable from "@/components/vessel/enquiry/VesselEnquiryCrewMemberPastContractsTable.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { VesselEnquiryModel } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { VesselEnquiryCrewMemberPastContractsTable } })
export default class VesselEnquiryClosedOverview extends Vue {
  private tableRefreshKey = false;

  private async onUpdateVesselEnquiry(item: VesselEnquiryModel) {
    if (!item.vesselEnquiryId) {
      return;
    }

    this.$router.push({
      name: "Vessel Enquiry Edit",
      params: { vesselEnquriyId: item.vesselEnquiryId }
    });
  }
}
